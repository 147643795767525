.home-content {
    height: 100vh;
}

.home-description {
    position: absolute;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    bottom: 200px;
    text-shadow: 4px 4px 4px #000000;
}

.home-description-typing-info {
    color: var(--color-white);
    text-shadow: 4px 4px 4px #000000;
    font-size: 40px;
    display: flex;
    flex-direction: row;
}

.Typewriter__wrapper {
    font-weight: bold;
    border-bottom: 3px solid var(--color-light-blue);
}

.Typewriter__cursor {
    color: var(--color-white);
    text-shadow: 0px 4px 4px #282828;
}