.navbar-header {
    background-color: var(--color-light-black);
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    border-top: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
    z-index: 100;
}

.navbar-header-inner {
    max-width: 80%;
    margin: 0 auto;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 88px;
}

.navbar-links {
    display: flex;
    flex-direction: row;
}

.navbar-links li {
    padding: 0 1rem;
    cursor: pointer;
    padding-top: 28px;
    padding-bottom: 29px;
    transition: .5s ease;
}

.underline {
    border-bottom: 4px solid transparent;
}

.navbar-links li:hover {
    color: var(--color-white);
    transition: .5s ease-in;
}

.activeClass {
    color: var(--color-white);
    font-size: bold;
    border-bottom: 4px solid #149ddd;
}

.activeClassMobileIcon {
    color: var(--color-light-blue);
}

.activeClassMobileText {
    color: var(--color-white);
}

.navbar-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-white);
    z-index: 1;
    gap: 4px;
    right: 0;
}

.navbar-logo-name {
    color: var(--color-white);
}

.navbar-logo-subtext {
    color: var(--color-light-blue);
    font-size: 15px;
}

.navbar-smallscreen {
    position: absolute;
    right: 0;
}

.navbar-smallscreen-toggle {
    width: 50px;
    height: 50px;
    top: 15px;
    right: 15px;
    position: relative;
    z-index: 9000;
    background-color: var(--color-light-blue);
    border-radius: 50%;
}

.navbar-smallscreen-logo {
    display: flex;
    flex-direction: column;
}

.navbar-smallscreen-overlay {
    width: 323px;
    height: 100vh;
    background: var(--color-light-grey);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 60px;
    overflow: auto;
    padding-left: 23px;
    padding-bottom: 60px;
    gap: 50px;
}

.hide {
    transform: translate3d(-100vw, 0, 0);
    opacity: 0;
}

.animated-hide {
    transform: translate3d(-100vw, 0, 0);
    transition: 0.85s ease;
    opacity: 0;
}

.show {
    transform: translate3d(0vw, 0, 0);
    transition: 0.85s ease;
    opacity: 1;
}

.navbar-smallscreen-overlay-links li {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 25px;
    cursor: pointer;
}

.navbar-smallscreen-overlay-icon-text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.navbar-smallscreen-overlay-icon-text:hover span {
    color: var(--color-white);
    transition: .5s ease-out;
}

.navbar-smallscreen-overlay-icon-text span {
    transition: .5s ease-out;
}

.navbar-smallscreen-overlay-icon-text:hover svg {
    color: var(--color-light-blue);
    transition: .5s ease-out;
}

.navbar-smallscreen-overlay-icon-text svg {
    transition: .5s ease-out;
}

.activeClassMobileText {
    color: var(--color-white);
}

.navbar-smallscreen-overlay-socials {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 35px;
}

.navbar-smallscreen-overlay-socials a {
    color: var(--color-white);
    transition: .5s ease-out;
}

.navbar-smallscreen-overlay-socials a:hover {
    color: var(--color-light-blue);
    transition: .5s ease-out;
}

@media screen and (min-width: 1025px) {
    .navbar-smallscreen {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .navbar-links {
        display: none;
    }

    .navbar-logo-name {
        font-size: 24px;
    }

    .navbar-logo-subtext {
        font-size: 13.5px;
    }

    .navbar {
        display: none;
    }
}