.scroll-to-top-button {
    position: fixed;
    right: 35px;
    bottom: 35px;
    width: 40px;
    height: 40px;
    font-size: 40px;
    border-radius: 8px;
    background-color: var(--color-grey);
    border: none;
    opacity: 0.7;
}

.scroll-button-animate-hide {
    opacity: 0;
    transition: .2s ease-in;
}

.scroll-button-animate-show {
    cursor: pointer;
    transition: .2s ease-in;
}

.scroll-button-animate-show > svg{
    color: var(--color-black);
}

.scroll-button-animate-show:hover {
    opacity: 1;
    background-color: var(--color-black);
    transition: .2s ease-in;
}

.scroll-button-animate-show:hover > svg{
    color: var(--color-light-blue);
}

@media screen and (max-width: 1024px) {
    .scroll-to-top-button {
        right: 15px;
        bottom: 15px;
    }
}