.about-container-inner-border-style::before {
    content: "";
    display: block;
    width: 100%;
    margin-bottom: 30px;
    border-top: 3px solid #149ddd;
}

.about-container-inner-border-style::after {
    content: "";
    display: block;
    width: 100%;
    margin-top: 40px;
    border-top: 3px solid #149ddd;
} 