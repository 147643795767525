.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-slide {
    width: 60%;
    left: 0px;
  }

  .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
  }