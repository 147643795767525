@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@350&display=swap');

:root {
    --color-black: rgb(0, 0, 0);
    --color-light-black: rgb(33, 36, 49);
    --color-navy-blue: rgb(23, 59, 108);
    --color-light-blue: rgb(20, 157, 221);
    --color-white: rgb(255, 255, 255);
    --color-grey: rgb(153, 153, 153);
    --color-light-grey: #2c2f3f;
    --color-powder-blue: rgb(182, 208, 226);
    --color-pastel-teal: rgb(17, 169, 168);
    --color-pastel-purple: rgb(154, 127, 174);
    --color-pastel-orange: rgb(238, 158, 45);
    --color-pastel-green: rgb(158, 203, 145);
    --color-pastel-red: rgb(210, 100, 102);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    font-family: "Roboto Slab", sans-serif;
}

ul, li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
}

.no-highlight {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.background-light-grey {
    background-color: var(--color-light-grey);
}

.background-light-black {
    background-color: var(--color-light-black);
}

.background-pastel-teal {
    background-color: var(--color-pastel-teal);
}

.background-pastel-purple {
    background-color: var(--color-pastel-purple);
}

.background-pastel-orange {
    background-color: var(--color-pastel-orange);
}

.background-pastel-red {
    background-color: var(--color-pastel-red);
}

.background-pastel-green {
    background-color: var(--color-pastel-green);
}

.p-nav-link-text {
    color: var(--color-grey);
    font-size: 21px;
}

.container {
    width: 100%;
    height: auto;
}

.container-inner {    
    max-width: 80%;
    margin: 0 auto;
    padding-top: 70px;
    padding-bottom: 70px;
}

.container-inner-no-padding {    
    max-width: 80%;
    margin: 0 auto;
}

h1, h2, h3, h4, h5, p {
    color: var(--color-white);
}

p, li {
    font-size: 20px;
}

.section-title h2 {
    font-size: 44px;
    text-align: center;
}

.section-title h3 {
    font-size: 30px;
}

.section-description {
    margin: 10px 0px 20px 0px;
}

.pastel-teal-border {
    border-color: var(--color-pastel-teal);
}

.pastel-purple-border {
    border-color: var(--color-pastel-purple);
}

.pastel-orange-border {
    border-color: var(--color-pastel-orange);
}

.pastel-green-border {
    border-color: var(--color-pastel-green);
}

.pastel-red-border {
    border-color: var(--color-pastel-red);
}

.heading-underline::after {
    content: "";
    display: block;
    width: 60px;
    height: 6px;
    background: #149ddd;
    bottom: 0;
    left: 0;
}

.section-list li {
    margin-left: 20px;
    margin-top: 6px;
    color: var(--color-white);
}

.section-list li > p {
    display: inline;
}

.section-list > li:before {
    color: var(--color-white);
    content: "○ ";
}

@media screen and (max-width: 1024px) {
    .container-inner {
        /* max-width: 85%; */
    }
}