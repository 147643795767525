.project-post-card-list {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 425px));
    grid-gap: 75px;
    justify-content: center;
    justify-items: center;
}

.project-section-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.postcard {
    flex: 0 0 33.3%;
    cursor: pointer;
    min-width: 325px;
    max-width: 450px;
    background-color: var(--color-light-black);
    border-radius: 10px;
    box-shadow: 0 0 24px 0 var(--color-black);
    transition: .5s ease;
    border: 2px solid var(--color-navy-blue);
}

.postcard-image-title {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
}

.postcard:hover {
    border: 2px solid var(--color-light-blue);
    cursor: default;
}

.postcard:hover img {
    transform: scale(1.1);
}

.postcard-image-container {
    position: relative;
    height: 300px;
    overflow: hidden;
    text-align: center;
    background-color: var(--color-black);
    border-radius: 10px;
}

.postcard-image-container img {
    max-width: 100%;
    min-height: 300px;
    object-fit: cover;
    transition: .5s ease;
}

.postcard-description {
    padding: 15px 15px;
}

.postcard-description-body {
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: space-between;
}

.postcard-description-tag-list {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.postcard-description-tag {
    padding: 2px 10px;
    text-align: center;
    border-radius: 20px;
}

.postcard-description-tag p {
    font-size: 15px;
    font-weight: bold;
}

